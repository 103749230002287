body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper{
  width: 100%;
  max-width: 100%;
  max-height: 60vh;
  min-width: 0px;
  transition-duration: 0ms;
  transform: translate3d(0px, 0px, 0px);
  transition-delay: 0ms;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 60vh;
}

.fact-section{
  padding-top: 0px;
}


.ts-facts-area-bg{
  background-color:#6d6e71;
}

.count-part{
  visibility: visible;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}




/* Facts
================================================== */
/* Skills image*/
.skills-image {
  height: 100%;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1500px) {
  .skills-image {
    min-height: 565px;
  }
}
.skills-image img {
  height: 100%;
  width: 100%;
}

/* Intro video
.intro-video {
  height: 578px;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .intro-video {
    min-height: 585px;
  }
}
.intro-video:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.35);
}
.intro-video:hover:after {
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media screen and (min-width: 1200px) {
  .intro-video img {
    width: 100%;
  }
}
@media screen and (max-width: 1366px) {
  .intro-video img {
    height: 100%;
  }
} */

.ts-facts-area [class*=col] {
  padding: 0;
}
.ts-facts-area .ts-padding {
  padding: 70px 140px 40px 50px;
}
.ts-facts-area .column-title {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 10px;
}

.ts-facts i {
  font-size: 48px;
  color: #a4a7af;
}

.ts-facts-num {
  font-size: 30px;
  font-weight: 700;
  color: #252a37;
  /* margin-top: 15px; */
  margin-bottom: 0;
  display: inline-block;
}

.facts h4 {
  font-size: 18px;
  margin-top: 8px;
  text-transform: uppercase;
}

/* Facts bg area */
.ts-facts-area-bg {
  /* background-image: url("../images/parallax/parallax1.jpg"); */
  /* padding: 60px 0; */
  padding:22px 0 22px;
  color: #ffffff;
  background-color: #08539c;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 50%;
}
.ts-facts-bg{
  display: flex;
}

.ts-facts-bg i {
  font-size: 48px;
  color: #fff;
}
.ts-facts-bg .ts-facts-num {
  color: #fff;
}


.ts-service-image-wrapper { overflow: hidden; } 
.ts-service-image-wrapper img{ transition: transform .5s; width: 612px; height: 284px; }
.ts-service-image-wrapper img:hover {-ms-transform: scale(1.5); -webkit-transform: scale(1.5); transform: scale(1.1); }
.ts-facts-content { line-height: 15px; float: left;  margin-left: 13px;} 
.ts-case-box a .prjimg{ transition: transform .5s;height: 238px; }
.ts-case-box a .prjimg:hover {-ms-transform: scale(1.5); -webkit-transform: scale(1.5); transform: scale(1.1); }

.clients-area.bg-white { background: #fff; }

.affiliation-logo {
  width: 100%;
  text-align: center;
}
.ts-case-challange.affiliation {
  padding-bottom: 70px;
}
.affiliation-logo span.logo-single {display: inline-block;
  width: auto;
  width: 23%;
  margin: 0 1%;
  margin-bottom: 15px;
  border: 0;
  text-align: center;
  min-height: unset;
  padding: 20px 0;
float:none;}

.affiliation-logo span.logo-single img {
  vertical-align: middle;
  height: 80px;
  /* display: table-cell; */
  float: none;
max-height: auto;
}
.affiliation-logo.affiliation-logo3 {
  margin-top: 40px;
}
.affiliation-logo span.logo-single:after {content: "";width: 1px;position: absolute;background:#e3e3e3;height: auto;right: 0;display: block;top: 15px;bottom: 15px;height: 97px;}
.affiliation-logo.affiliation-logo2 .logo-single::after {
  right: -19px !important;
}
.affiliation-logo span.logo-single {
  position: relative;
}

.affiliation-logo span.logo-single:last-child::after {
  display: none;
}
.clients-area.clients-area2 .affiliation-logo.affiliation-logo3.wow.fadeInUp {
  margin-top: 0;
}
.clients-area.clients-area2 {
  padding: 60px 0 0 0 !important;
  margin-bottom: 120px;
  border-top: 1px solid #eee;
  /* float: left; */
  width: 100%;
}
.logo-single.last-logo {
  padding-right: 0 !important;
}
.affiliation-logo.affiliation-logo4 .logo-single {
  width: auto !important;
  float: left;
  margin:0 0px 0 54px;
  padding-right:53px;
}
.affiliation-logo.affiliation-logo3.wow.fadeInUp .logo-single {
  width: auto !important;
  margin:0 0 0 36px !important;
  padding-right: 36px;
}
.affiliation-logo.affiliation-logo2 .logo-single {
  width: 34% !important;
  margin: 0 !important;
  padding: 14px 16px 0 0 !important;
}

.affiliation-logo.affiliation-logo4.wow.fadeInUp.aff-logo {
  margin: 50px 0 190px !important;
}

.affiliation-logo.affiliation-logo3 .logo-single:after {
  height: 124px;
}

.ts-service-box .service-more {
  cursor: inherit;
}

/* .ts-service-image-wrapper img{
  width: 255px;
  height: 182px;
} */



/*-- Footer top --*/

.footer-top-section{
  padding:0px;
}
/* .footer-top { */
  /* position: absolute;
  top: -60px;
  z-index: 1;
  margin: 0 auto;
  width: 100%; */
/* } */

.footer-top-bg {
  background:#08539c;
  margin: 0;
  color: #fff;
}

.footer-top .footer-box {
  /* padding: 30px 45px 20px; */
  padding: 30px 120px 20px;
  min-height: 120px;
}
.footer-top .footer-box i {
  float: left;
  font-size: 28px;
  margin-top: 0px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 100%;
}
.footer-top .footer-box i:hover{
  transform: scale(1.1);
  background: rgb(47 149 38);
}
.footer-top .footer-box .footer-box-content {
  /* margin-left: 90px; */
  margin-left: 80px;
}
.footer-top .footer-box .footer-box-content h3 {
  margin-top: 6px;
  margin-bottom: 2px;
  font-size: 20px;
  color: #fff;
  
}
.footer-top .footer-box .footer-box-content p {
  margin: 0;
  color: #fff;
  font-size: 14px;
}
.footer-top .footer-box .footer-box-content p a {color:#fff;}

.whatsapp{
  float: right !important;
  margin-top: 35px !important;
}

.whatsapp i{
  color: #059652 !important;
  font-size: 35px !important;
}


.swiper-container .swiper-zoom-container>img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}


.btn:hover{
  background-color: rgb(47 149 38);
  border-color: rgb(47 149 38);
}

.footer{
  background: url(../public/assets/img/footer/footer-overlay1.png);
  background-size: cover;
  background-position: 100%;
  position: relative;
  /* padding: 120px 0 20px; */
  color: #fff;
}

.product-component section, .suppliers-component section{
  padding: 0px;
}




/* CSS for devices with a screen width up to 767px (typical mobile devices) */
@media only screen and (max-width: 767px) {
  /* Your styles for mobile devices go here */
  .product-card{
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 10px 15px #b6c1c5;
  }
}


/* CSS for devices with a screen width between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Your styles for tablet devices go here */
  .product-card{
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 10px 15px #b6c1c5;
  }
}



/* CSS for landscape-oriented tablet devices */
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
  /* Your styles for landscape tablet devices go here */
  .product-card{
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 10px 15px #b6c1c5;
  }
}


/* CSS for common mobile and tablet devices */
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  /* Your shared styles for mobile and tablet devices go here */
  .product-card{
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 10px 15px #b6c1c5;
  }
}
